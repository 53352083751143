import { MapPinIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { useState, useEffect, useRef, FC } from "react";

import toast from "react-hot-toast";
import LatLng from "models/Latlng";
import { useData } from "data/data-provider";
import { getNearbyLocations, searchLocations } from "api/locations/locations";
import LocationModal from "components/HeroSearchForm/LocationModal";
import LocationDropoffModal from "components/HeroSearchForm/LocationDropoffModal";

interface Props {
  onClick?: () => void;
  onChange?: (value: string) => void;
  className?: string;
  defaultValue?: string;
  headingText?: string;
  setShowModal: (show: boolean) => void;
}

const LocationInput: FC<Props> = ({
  onChange = () => {},
  className = "",
  defaultValue = "United States",
  headingText = "Where to?",
  setShowModal,
}) => {
  const {
    ourLocation,
    setPickupLocation,
    setSearchedPickupLocation,
    setSearchedDropoffLocation,
    setDropoffLocation,
    ourCompany,
  } = useData();
  const [value, setValue] = useState("");
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [showDropoffLocationModal, setShowDropoffLocationModal] =
    useState(false);
  const [selectedLocation, setSelectedLocation] = useState<any>(null);

  const containerRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleSelectLocation = (item: any) => {
    if (headingText === "Pick up?") {
      setSearchedPickupLocation("");
      setPickupLocation(null);
      if (item.radius) {
        setSelectedLocation(item); // Store selected location
        setShowLocationModal(true); // Open modal if location has a radius
      } else {
        setPickupLocation(item);
        setValue(item.address);
        onChange(item.address);
      }
    } else if (headingText === "Drop off?") {
      setSearchedDropoffLocation("");
      setDropoffLocation(null);
      if (item.radius) {
        setSelectedLocation(item); // Store selected location
        setShowDropoffLocationModal(true); // Open modal if location has a radius
      } else {
        setDropoffLocation(item);
        setValue(item.address);
        onChange(item.address);
      }
    }
  };

  const renderRecentSearches = () => {
    const filterLocations = ourLocation.filter((item) => {
      if (headingText === "Pick up?") {
        return (
          item.active !== false &&
          (item.location_type === "pick_up" || item.location_type === "both")
        );
      } else if (headingText === "Drop off?") {
        return (
          item.active !== false &&
          (item.location_type === "drop_off" || item.location_type === "both")
        );
      }
      return false;
    });

    return (
      <div className="mt-2">
        {filterLocations
          .sort((a, b) => a.price - b.price)
          .map((item) => (
            <span
              onClick={() => handleSelectLocation(item)}
              key={item.id}
              className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer justify-between"
            >
              <div className="flex">
                <span className="block text-neutral-400 max-sm:mt-1 mt-0">
                  <MapPinIcon className="h-4 sm:h-6 w-4 sm:w-6" />
                </span>
                <span className="text-left block font-medium text-neutral-700 dark:text-neutral-200 mx-2">
                  {item.address}
                </span>
              </div>
              <span className=" block font-medium text-neutral-700 dark:text-neutral-200">
                {item.price == 0 ? "Free" : "$" + item.price + "/trip"}
              </span>
            </span>
          ))}
      </div>
    );
  };

  return (
    <div className={`${className}`} ref={containerRef}>
      <div className="p-5 ">
        <span className="block font-semibold text-xl sm:text-2xl ">
          {headingText}
        </span>
        <div className="relative mt-5">
          <input
            className="block w-full bg-transparent border px-4 py-3 pr-12 border-neutral-900 dark:border-neutral-200 rounded-xl focus:ring-0 focus:outline-none text-base leading-none placeholder-neutral-500 dark:placeholder-neutral-300 truncate font-bold placeholder:truncate"
            placeholder={"Search destinations"}
            value={value}
            onChange={(e) => {
              setValue(e.currentTarget.value);
            }}
            ref={inputRef}
            readOnly
          />
          <span className="absolute right-2.5 top-1/2 -translate-y-1/2">
            <MagnifyingGlassIcon className="w-5 h-5 text-neutral-700 dark:text-neutral-400" />
          </span>
        </div>
        <div className="mt-7">{renderRecentSearches()}</div>
        <LocationModal
          isOpen={showLocationModal}
          selectedLocation={selectedLocation}
          onClose={() => setShowLocationModal(false)}
          ourCompany={ourCompany}
          setSearchedPickupLocation={setSearchedPickupLocation}
          setPickupLocation={setPickupLocation}
          ourLocation={ourLocation}
          setValue={setValue}
          onChange={onChange}
        />
        <LocationDropoffModal
          isOpen={showDropoffLocationModal}
          selectedLocation={selectedLocation}
          onClose={() => setShowDropoffLocationModal(false)}
          ourCompany={ourCompany}
          setSearchedDropoffLocation={setSearchedDropoffLocation}
          setDropoffLocation={setDropoffLocation}
          ourLocation={ourLocation}
          setValue={setValue}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default LocationInput;
