import { FC, useState, useEffect, useRef } from "react";
import { MapPinIcon } from "@heroicons/react/24/outline";
import toast from "react-hot-toast";
import { getNearbyLocations, searchLocations } from "api/locations/locations";
import LatLng from "models/Latlng";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css"; // Import Leaflet CSS
import L from "leaflet"; // Import Leaflet for custom icon

interface MatchedSubstring {
  length: number;
  offset: number;
}

interface StructuredFormatting {
  main_text: string;
  main_text_matched_substrings: MatchedSubstring[];
  secondary_text: string;
}

interface Term {
  offset: number;
  value: string;
}

interface LocationPrediction {
  description: string;
  matched_substrings: MatchedSubstring[];
  place_id: string;
  reference: string;
  structured_formatting: StructuredFormatting;
  terms: Term[];
  types: string[];
}

interface SearchResults {
  predictions: LocationPrediction[];
  status: string;
}

interface LocationModalProps {
  isOpen: boolean;
  selectedLocation: any;
  onChange?: (value: string) => void;
  onClose: () => void;
  ourLocation: any[];
  setPickupLocation: (location: any) => void;
  setSearchedPickupLocation: (location: any) => void;
  setValue?: (location: any) => void;
  ourCompany: { distance_unit: string } | null;
}

const LocationModal: FC<LocationModalProps> = ({
  isOpen,
  selectedLocation,
  onClose,
  ourLocation,
  setPickupLocation,
  setSearchedPickupLocation,
  onChange,
  setValue,
  ourCompany,
}) => {
  const [searchedLocation, setSearchedLocation] = useState<any>("");
  const [locationDetails, setLocationDetails] = useState<LatLng | null>(null);
  const [searchedLocations, setSearchedLocations] = useState<
    LocationPrediction[]
  >([]);
  const [showPopover, setShowPopover] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const customIcon = L.icon({
    iconUrl: "https://cdn-icons-png.flaticon.com/512/684/684908.png", // URL to your custom icon
    iconSize: [32, 32], // size of the icon
    iconAnchor: [16, 32], // point of the icon which will correspond to marker's location
    popupAnchor: [0, -32], // point from which the popup should open relative to the iconAnchor
  });

  const defaultCenter = { latitude: 37.7749, longitude: -122.4194 };

  // Set the map center based on the selected location or default location
  const center = selectedLocation || defaultCenter;

  const calculateDistance = (start: LatLng, end: LatLng): number => {
    const earthRadius = ourCompany?.distance_unit === "miles" ? 3958.8 : 6371;
    const radians = (degrees: number): number => degrees * (Math.PI / 180);

    const lat1 = radians(start.latitude);
    const lon1 = radians(start.longitude);
    const lat2 = radians(end.latitude);
    const lon2 = radians(end.longitude);

    const dLat = lat2 - lat1;
    const dLon = lon2 - lon1;

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1) * Math.cos(lat2) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return earthRadius * c;
  };

  const fetchLocationDetails = async (placeId: string) => {
    try {
      const locationData = (await getNearbyLocations(placeId)) as {
        result: { geometry: { location: { lat: number; lng: number } } };
      };

      if (locationData.result) {
        setLocationDetails({
          latitude: locationData.result.geometry.location.lat,
          longitude: locationData.result.geometry.location.lng,
        });
      } else {
        toast.error(`Place Details API error`);
      }
    } catch (error) {
      toast.error("Error fetching location details: " + error);
    }
  };

  useEffect(() => {
    if (isOpen) {
      // Reset state variables when the modal is opened
      setSearchedLocation("");
      setLocationDetails(null);
      setSearchedLocations([]);
      setShowPopover(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (selectedLocation?.place_id) {
      fetchLocationDetails(selectedLocation.place_id);
    }
  }, [selectedLocation]);

  const handleSearch = async (input: string) => {
    if (!input) return;

    try {
      const results: SearchResults = (await searchLocations(
        input
      )) as SearchResults;

      if (results.status === "OK") {
        setSearchedLocations(results.predictions);
      }
    } catch (error) {
      toast.error("Error fetching search results");
    }
  };

  const handleLocationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchedLocation(value);
    handleSearch(value);
  };

  const checkLocationInRange = async (
    placeId: string
  ): Promise<LatLng | null> => {
    try {
      const placeDetailsResponse = (await getNearbyLocations(placeId)) as any;

      if (placeDetailsResponse.result) {
        const lat = placeDetailsResponse.result.geometry.location.lat;
        const lng = placeDetailsResponse.result.geometry.location.lng;
        return { latitude: lat, longitude: lng };
      } else {
        toast.error(`Place Details API error: ${placeDetailsResponse.status}`);
      }
    } catch (error) {
      toast.error("Error fetching location details:" + error);
    }

    return null;
  };

  const handleSelectSearchedLocation = async (item: LocationPrediction) => {
    const position = (await checkLocationInRange(
      item.place_id
    )) as LatLng | null;

    let minDistanceDeliveryLocation: any = null;
    let minDistance = Infinity;

    if (position) {
      for (const location of ourLocation ?? []) {
        if (location.radius !== null) {
          const startLatLng = {
            latitude: Number(location.latitude),
            longitude: Number(location.longitude),
          };

          const endLatLng = {
            latitude: Number(position.latitude),
            longitude: Number(position.longitude),
          };

          let distance = calculateDistance(startLatLng, endLatLng);
          if (ourCompany?.distance_unit === "miles") {
            distance /= 1.609344;
          }

          if (distance <= location.radius) {
            if (minDistance > distance) {
              minDistanceDeliveryLocation = location;
              minDistance = distance;
            }
          }
        }
      }
    }

    if (minDistanceDeliveryLocation) {
      setPickupLocation(minDistanceDeliveryLocation);
      setSearchedLocation(item.description);
      setLocationDetails(position);
      setShowPopover(false);
    } else {
      toast.error(
        "Sorry, this location is not within the allowed radius. Try a nearby location."
      );
    }
  };

  const handleClickOutsidePopover = (event: MouseEvent) => {
    if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
      setShowPopover(false);
    }
  };
  const handleSelectLocation = () => {
    setSearchedPickupLocation(searchedLocation);

    if (setValue) {
      setValue(searchedLocation ? searchedLocation : "United States");
    }
    if (onChange) {
      onChange(searchedLocation);
    }
    onClose();
  };

  useEffect(() => {
    if (showPopover) {
      document.addEventListener("click", handleClickOutsidePopover);
    } else {
      document.removeEventListener("click", handleClickOutsidePopover);
    }

    return () => {
      document.removeEventListener("click", handleClickOutsidePopover);
    };
  }, [showPopover]);

  return isOpen ? (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="m-4 md:m-0  bg-white dark:bg-neutral-800 rounded-lg p-6 max-w-lg w-full relative">
        <div className="flex justify-between items-center">
          <h2 className="text-lg font-medium">
            Select a custom address within {selectedLocation.radius}{" "}
            {ourCompany?.distance_unit === "km" ? "Kilometers" : "miles"}
          </h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-600"
          >
            ✖
          </button>
        </div>
        <div className="mt-4">
          <div className="flex items-center space-x-3 mb-4">
            <MapPinIcon className="w-5 h-5 text-neutral-400" />
            <Input
              type="text"
              value={selectedLocation?.address || ""}
              readOnly
              className="w-full p-2 bg-neutral-100 dark:bg-neutral-700 rounded-md focus:ring-primary-200"
            />
          </div>

          <div className="relative">
            <div className="flex items-center space-x-3 mb-4">
              <MapPinIcon className="w-5 h-5 text-neutral-400" />
              <Input
                type="text"
                value={searchedLocation}
                onChange={handleLocationChange}
                onFocus={() => setShowPopover(true)}
                ref={inputRef}
                placeholder="Search for a location"
                className="w-full p-2 bg-neutral-100 dark:bg-neutral-700 rounded-md focus:ring-primary-200"
              />
            </div>

            {showPopover &&
              searchedLocation &&
              searchedLocations.length > 0 && (
                <div
                  className="absolute left-0 z-40 w-full min-w-[300px] sm:min-w-[500px] bg-white dark:bg-[#140B0B] border border-[#E5E7EB] top-full mt-3 py-3 sm:py-6 rounded-3xl shadow-xl max-h-96 overflow-y-auto hide-scrollbar"
                  style={{ zIndex: 9999 }}
                >
                  {searchedLocations.map((location, index) => (
                    <span
                      key={index}
                      onClick={() => handleSelectSearchedLocation(location)}
                      className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 hover:bg-neutral-100 dark:hover:bg-primary-200 cursor-pointer justify-between"
                    >
                      <div className="flex">
                        <MapPinIcon className="h-4 w-4 sm:h-6 sm:w-6 text-neutral-400" />
                        <span className="text-left block font-medium text-neutral-700 dark:text-neutral-200 mx-2">
                          {location.structured_formatting.main_text},{" "}
                          {location.structured_formatting.secondary_text}
                        </span>
                      </div>
                    </span>
                  ))}
                </div>
              )}
          </div>
          {locationDetails && (
            <div
              style={{
                width: "100%",
                height: "300px",
                borderRadius: "8px",
                overflow: "hidden",
              }}
            >
              <MapContainer
                center={[center.latitude, center.longitude]}
                zoom={13}
                style={{ width: "100%", height: "100%" }}
              >
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                <Marker
                  position={[center.latitude, center.longitude]}
                  icon={customIcon}
                >
                  <Popup>Your selected location.</Popup>
                </Marker>
              </MapContainer>
            </div>
          )}
        </div>
        <div className="mt-6">
          <ButtonPrimary
            onClick={handleSelectLocation}
            className=" text-white px-4 py-2 w-full rounded-md"
          >
            Select This Location
          </ButtonPrimary>
        </div>
      </div>
    </div>
  ) : null;
};

export default LocationModal;
