import { UserCircleIcon, HomeIcon } from "@heroicons/react/24/outline";
import { useData } from "data/data-provider";
import React, { useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import MenuBar from "shared/MenuBar/MenuBar";
import isInViewport from "utils/isInViewport";

let WIN_PREV_POSITION = window.pageYOffset;

interface NavItem {
  name: string;
  link?: string;
  icon: React.ComponentType<any> | JSX.Element;
  onClick?: () => void;
}

const FooterNav: React.FC = () => {
  const { user, setIsAuthPanelOpen } = useData();
  const containerRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const navigate = useNavigate(); // Use navigate to handle routing

  const NAV: NavItem[] = [
    {
      name: "Home",
      link: "/",
      icon: HomeIcon,
    },
    user
      ? {
          name: "Profile",
          link: "/account",
          icon: (
            <Avatar
              sizeClass="w-8 h-8 sm:w-9 sm:h-9"
              imgUrl={user?.profile_picture ?? ""}
            />
          ),
        }
      : {
          name: "Log in",
          icon: UserCircleIcon,
        },
    {
      name: "Menu",
      icon: <MenuBar />,
    },
  ];

  useEffect(() => {
    const handleEvent = () => {
      window.requestAnimationFrame(showHideHeaderMenu);
    };

    window.addEventListener("scroll", handleEvent);
    return () => {
      window.removeEventListener("scroll", handleEvent);
    };
  }, []);

  const showHideHeaderMenu = () => {
    let currentScrollPos = window.pageYOffset;
    if (!containerRef.current) return;

    if (currentScrollPos > WIN_PREV_POSITION) {
      if (
        isInViewport(containerRef.current) &&
        currentScrollPos - WIN_PREV_POSITION < 80
      ) {
        return;
      }

      containerRef.current.classList.add("FooterNav--hide");
    } else {
      if (
        !isInViewport(containerRef.current) &&
        WIN_PREV_POSITION - currentScrollPos < 80
      ) {
        return;
      }
      containerRef.current.classList.remove("FooterNav--hide");
    }

    WIN_PREV_POSITION = currentScrollPos;
  };

  const renderIcon = (
    icon: React.ComponentType<any> | JSX.Element,
    active: boolean
  ) => {
    const className = `w-6 h-6 ${active ? "text-[#00A1F1]" : ""}`;

    if (React.isValidElement(icon)) {
      return React.cloneElement(icon, {
        className,
      } as React.HTMLAttributes<HTMLElement>);
    } else {
      const IconComponent = icon as React.ComponentType<any>;
      return <IconComponent className={className} />;
    }
  };

  const handleLoginClick = () => {
    if (!user) {
      setIsAuthPanelOpen(true); // Open the login modal
    }
  };

  return (
    <div
      ref={containerRef}
      className="FooterNav p-2 bg-white dark:bg-neutral-800 fixed top-auto bottom-0 inset-x-0 z-30 border-t border-neutral-300 dark:border-neutral-700 
      transition-transform duration-300 ease-in-out"
    >
      <div className="w-full max-w-lg flex justify-around mx-2 text-sm text-center p-5">
        {NAV.map((item, index) => {
          const active = location.pathname === item.link;
          const content = (
            <>
              {renderIcon(item.icon, active)}
              <span className="text-[11px] leading-none mt-1">{item.name}</span>
            </>
          );

          return item.name === "Log in" ? (
            <div
              key={index}
              onClick={handleLoginClick} // Trigger login modal
              className={`flex flex-col items-center justify-between text-neutral-500 dark:text-neutral-300/90 ${
                active ? "text-[#00A1F1] dark:text-neutral-100" : ""
              } cursor-pointer`} // Add cursor-pointer to indicate it's clickable
            >
              {content}
            </div>
          ) : item.link ? (
            <Link
              key={index}
              to={item.link}
              onClick={item.name === "Log in" ? handleLoginClick : undefined}
              className={`flex flex-col items-center justify-between text-neutral-500 dark:text-neutral-300/90 ${
                active ? "text-neutral-900 dark:text-neutral-100" : ""
              }`}
            >
              {content}
            </Link>
          ) : (
            <div
              key={index}
              onClick={item.onClick} // Handle click event
              className={`flex flex-col items-center justify-between text-neutral-500 dark:text-neutral-300/90 ${
                active ? "text-neutral-900 dark:text-neutral-100" : ""
              } cursor-pointer`} // Add cursor-pointer to indicate it's clickable
            >
              {content}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FooterNav;
