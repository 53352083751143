import clientSay1 from "images/clientSay1.jpg";
import clientSay4 from "images/clientSay4.jpg";
import clientSay5 from "images/clientSay5.jpg";

export const globalJson = {
  prod_company_id: 27,
  dev_company_id: 1,

  // apiUtil auth header key
  authToken: "Bearer 171|oi0d3wgjpOBpuLKAqYnmU7XkzvlDTmAzmz1GzktC",
  companyId: "b8cb93b3-d95e-4fb8-ae13-146b4f64846f",
  stripePublishableKey:
    "pk_live_51PcuOnRra0lXsHPw9BuezMbtZC4UWDoKe79TEoh0o2RR47w1UKRBUEV4NTvWw6ulDk2junjATRHqTRea7wfOUSZT00h9ATeTiV",
  mapKey: "AIzaSyDKEntg7JydrxI7zr2tQL5OYcS5M-er-bc",
  residenceStateCode: "FL",

  adminEmailToken:
    "eSARL0qbFbAkFtLBzx2FajpsjqymAJPZkuo9Ojrb7WR5XLCt4VckcgVY2I5TK10szd1F8bd7MBkR4di0oui7Z1QGpzty9Mn4oTT2Fxfx4v42nydAb7y0rSkwtHE5ZWHt",
  websiteLink: "https://auto-motovation-ent-dev.1now.app/", //update from doc when provided
  sender: "auto-motovation",
  ccEmail: "navin.seedath@gmail.com",

  // apiUtil sendNewsLetterEmail
  util_serviceId: "service_qtp0dg4",
  util_templateId: "template_zzaqcrs",
  util_userId: "5BABZb1-X9rZHDjmd",
  util_privateKey: "PLD5wzVCYqXSYCkfsMH-a",

  about_us_para:
    "Welcome to Direct Point International LLC, where we elevate your travel experience with unmatched quality and service. As a leading rental car provider, we are dedicated to delivering a smooth and exceptional journey for every traveler. Recognizing that every trip is distinct, we aim to be your reliable partner on the road. Our carefully curated fleet includes everything from elegant sedans to roomy SUVs, ensuring we have the ideal vehicle for any occasion.",

  clientSays_arr: [
    {
      id: 1,
      clientName: "Ushane H.",
      clientAddress: "Dodge Grand Caravan 2019",
      content:
        "Excellent service, car was good and communication with the host was excellent. Next time car will be my first option.",
      img: clientSay1,
    },
    {
      id: 2,
      clientName: "Jenna N.",
      clientAddress: "LondDodge Grand Caravan 2019",
      content:
        "Renting this vehicle was a great experience! The car was spacious (we traveled with 2 babies,) clean, and the A/C works like a champ 😎 Navin was on top of everything and answered all messages immediately. Pick up and drop off at the airport was so efficient! I hope to rent from Navin again.",
      img: clientSay4,
    },
    {
      id: 3,
      clientName: "Yalexandra V.",
      clientAddress: "Dodge Grand Caravan 2019",
      content:
        "Excellent comunication with Navin every time during the trip. Precise instructions about picking and returning car process. The car was in great condition. Hope to book soon again with Navin!!",
      img: clientSay5,
    },
  ],

  //social links
  facebook: "https://www.facebook.com/", //pending
  instagram: "https://www.instagram.com/", //pending

  //contact
  address: "4519 E AQUA BELLA LN, Ft Lauderdale, Florida 33312",
  addressLink:
    "https://www.google.com/maps/place/4519+E+Aqua+Bella+Ln,+Davie,+FL+33312,+USA/@25.7467918,-81.0646035,217823m/data=!3m1!1e3!4m6!3m5!1s0x88d9aa1e5e8e6887:0x9bdc08a3c19d02c4!8m2!3d26.0650294!4d-80.1850178!16s%2Fg%2F11n34fz94t?entry=ttu",
  phone: "9545935991",
  email: "admin@drivewithdp.com",
};

export const scriptHubSpot = document.createElement("script");
scriptHubSpot.src = "//js-na1.hs-scripts.com/46614194.js";
scriptHubSpot.async = true;
scriptHubSpot.defer = true;
scriptHubSpot.id = "hs-script-loader";
