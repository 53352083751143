import Logo from "shared/Logo/Logo";
import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { FooterLink } from "data/types";
import React from "react";
import { globalJson } from "global/global_json";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: FooterLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
  {
    id: "5",
    title: "Address",
    menus: [
      {
        href: globalJson.addressLink,
        label: globalJson.address,
      },
    ],
  },
  {
    id: "1",
    title: "Contact Us",
    menus: [{ label: globalJson.phone }, { label: globalJson.email }],
  },
  {
    id: "2",
    title: "Explore",
    menus: [
      { href: "/listing-car", label: "Fleet" },
      { href: "/about", label: "About Us" },
      { href: "/contact", label: "Contact" },
    ],
  },
];

const Footer: React.FC = () => {
  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className="text-sm lg:ml-16 sm:ml-0">
        <h2 className="font-semibold text-[#1B2430] dark:text-neutral-200">
          {menu.title}
        </h2>
        <ul className="mt-5 space-y-4">
          {menu.menus.map((item, index) => (
            <li key={index}>
              {item.href ? (
                <a
                  className="text-[#1B2430] dark:text-[#1B2430] hover:text-black dark:hover:text-white"
                  href={item.href}
                >
                  {item.label}
                </a>
              ) : (
                <span className="text-[#1B2430] dark:text-[#1B2430]">
                  {item.label}
                </span>
              )}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="nc-Footer relative py-8 lg:py-8 border-t border-neutral-200 dark:border-neutral-700">
      <div className="container grid grid-cols-1 gap-y-10 gap-x-4 sm:gap-x-8 md:grid-cols-1 lg:grid-cols-4 lg:gap-x-10 ">
        <div className="grid grid-cols-4 gap-5 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
          <div className="col-span-2 md:col-span-1">
            <Logo />
          </div>
          <div className="hidden lg:flex col-span-2 items-center md:col-span-3">
            <SocialsList1 className="flex items-center space-x-3 lg:space-x-0 lg:flex-col lg:space-y-2.5 lg:items-start" />
          </div>
        </div>
        {widgetMenus.map(renderWidgetMenuItem)}
        <SocialsList1 className="lg:hidden flex items-start space-x-3 lg:flex-col lg:space-y-2.5 lg:items-start" />
      </div>
    </div>
  );
};

export default Footer;
