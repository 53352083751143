import rightImg from "images/about-img.png";
import rightImg1 from "images/abouting2.png";
import React, { FC } from "react";
import SectionFounder from "./SectionFounder";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import { globalJson } from "global/global_json";
import GoPlacesWith from "components/GoPlacesWith/GoPlacesWith";
import Features from "containers/Feature/Feature";
import SectionHero2 from "./SectionHero2";
import GoPlacesWith2 from "components/GoPlacesWith2/GoPlacesWith2";

export interface PageAboutProps {
  className?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>About</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-12 ">
        <SectionHero
          rightImg={rightImg}
          heading="Welcome to Direct Point"
          btnText=""
          subHeading={globalJson.about_us_para}
        />
      </div>
      <div className="container py-12 ">
        <SectionHero2
          rightImg={rightImg1}
          heading="Our Vision"
          btnText=""
          subHeading="To be the premier destination for affordable and diverse car rental solutions, where every customer experiences exceptional one-on-one service and 24/7 availability. We aim to empower our clients with reliable transportation options that enhance their journeys, fostering lasting relationships built on trust, convenience, and satisfaction."
        />
      </div>

      <div className="relative p-16">
        <GoPlacesWith2 />
      </div>
      <div className="py-16 ">
        <Features />
      </div>

      {/* <BackgroundSection /> */}
      <div className="bg-[#EDEFFF] mt-12">
        <div className="relative py-16 mb-20 lg:mb-36">
          <SectionClientSay uniqueClassName="PageAbout_" />
        </div>
      </div>

      {/* <SectionStatistic />

      <SectionSubscribe2 /> */}
    </div>
  );
};

export default PageAbout;
