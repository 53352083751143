"use client";

import { FC } from "react";
import LocationInput from "../LocationInput";
import RentalCarDatesRangeInput from "./RentalCarDatesRangeInput";
import RentalCarTimesRangeInput from "./RentalCarTimesRangeInput";
import DropOffLocationInput from "../DropOffLocationInput";
import { useData } from "data/data-provider";

export interface RentalCarSearchFormProps {}

const RentalCarSearchForm: FC<RentalCarSearchFormProps> = () => {
  const {
    dropOffLocation,
    setPickupLocation,
    setDropoffLocation,
    setSearchedPickupLocation,
    setSearchedDropoffLocation,
    pickupLocation,
    setDropoffLocationType,
    setSameDropoffLocation,
    dropoffLocationType,
  } = useData();

  const renderRadioBtn = () => {
    return (
      <div className=" py-5 [ nc-hero-field-padding ] flex items-center flex-wrap flex-row border-b border-neutral-100 dark:border-neutral-700">
        <div
          className={`py-1.5 px-4 flex items-center rounded-full font-medium text-xs cursor-pointer mr-2 my-1 sm:mr-3 ${
            dropoffLocationType === true
              ? "bg-black text-white shadow-black/10 shadow-lg"
              : "border border-neutral-300 dark:border-neutral-700"
          }`}
          onClick={(e) => {
            setDropoffLocationType(true);
            setDropoffLocation(pickupLocation);
            setSearchedDropoffLocation(pickupLocation?.name ?? "");
            setSameDropoffLocation(true);
            setPickupLocation(null);
            setDropoffLocation(null);
            setSearchedPickupLocation("");
            setSearchedDropoffLocation("");
          }}
        >
          Same drop off
        </div>
        <div
          className={`py-1.5 px-4 flex items-center rounded-full font-medium text-xs cursor-pointer mr-2 my-1 sm:mr-3 ${
            dropoffLocationType === false
              ? "bg-black text-white shadow-black/10 shadow-lg"
              : "border border-neutral-300 dark:border-neutral-700"
          }`}
          onClick={() => {
            setDropoffLocationType(false);
            setSameDropoffLocation(false);
            // Reset values
            setPickupLocation(null);
            setDropoffLocation(null);
            setSearchedDropoffLocation("");
            setSearchedPickupLocation("");
          }}
        >
          Different drop off
        </div>
      </div>
    );
  };

  const isDdropOffdifferent = dropoffLocationType === false;

  return (
    <form className="w-full relative mt-8 rounded-[40px] xl:rounded-[49px] rounded-t-2xl xl:rounded-t-3xl shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800">
      <div className="flex flex-start ml-12 pt-4">
        <span className="text-2xl font-semibold">
          Select Location, Date & Time.{" "}
          <span className="text-primary-200">Start your journey here</span>
        </span>
      </div>
      {renderRadioBtn()}
      <div className={`relative flex flex-row`}>
        <LocationInput
          placeHolder="Pick up Location"
           desc="Pick up Location"
          className="flex-1"
          sameDropOffLocation={dropoffLocationType === false}
        />
        {isDdropOffdifferent && (
          <>
            <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
            <DropOffLocationInput
              placeHolder="City or Airport"
              desc="Drop off location"
              className="flex-1"
              divHideVerticalLineClass="-inset-x-0.5"
            />
          </>
        )}
        <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
        <RentalCarDatesRangeInput className="flex-1" />
        <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
        <RentalCarTimesRangeInput
          dropOffLocationType={dropoffLocationType}
          className="flex-2"
        />
      </div>
    </form>
  );
};

export default RentalCarSearchForm;
