import React, { FC, ReactNode } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface SectionHeroProps {
  className?: string;
  rightImg: string;
}

const HomeAboutSection: FC<SectionHeroProps> = ({
  className = "",
  rightImg,
}) => {
  return (
    <div
      className={`nc-SectionHero relative ${className} mb-20 lg:mb-36`}
      data-nc-id="SectionHero"
    >
      <div className="flex flex-col lg:flex-row items-center relative text-center lg:text-left lg:space-x-10">
        {/* Text Section with 50% width */}
        <div className="w-full lg:w-1/2 space-y-5 lg:space-y-7">
          <h2 className="text-3xl !leading-tight font-bold md:text-4xl text-[#3B3B3B]">
            Welcome to DirectPoint
          </h2>
          <span className="block text-base xl:text-lg text-[#1B2430] text-justify md:max-w-[90%]">
            Welcome to Direct Point International LLC, where we elevate your
            travel experience with unmatched quality and service. As a leading
            rental car provider, we are dedicated to delivering a smooth and
            exceptional journey for every traveler. Recognizing that every trip
            is distinct, we aim to be your reliable partner on the road. Our
            carefully curated fleet includes everything from elegant sedans to
            roomy SUVs, ensuring we have the ideal vehicle for any occasion.
          </span>
        </div>

        {/* Image Section with 50% width */}
        <div className="w-full mt-12 lg:mt-0 lg:w-1/2 flex-grow">
          <img className="w-full rounded-xl" src={rightImg} alt="" />
        </div>
      </div>
    </div>
  );
};

export default HomeAboutSection;
