import { VehiclePayload, VehicleResponse } from "api/types";
import { AxiosError } from "axios";
import customAxios from "config";
import { globalJson } from "global/global_json";

export const getVehicleData = async (): Promise<{
  data?: VehicleResponse[];
  error?: string;
}> => {
  try {
    const response = await customAxios.get("/api/fleet/", {
      params: {
        company_id:  process.env.REACT_APP_ENV !== "prod"
            ? globalJson.dev_company_id
            : globalJson.prod_company_id,
      },
    });
    return { data: response.data };
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      return {
        error:
          error.response.data.detail ||
          "An error occurred while fetching fleet data.",
      };
    }
    return { error: "An unknown error occurred." };
  }
};

export const getAvailableVehicleData = async (
  params: VehiclePayload
): Promise<{ data?: VehicleResponse[] | null; error?: string }> => {
  try {
    const response = await customAxios.get("/api/fleet/", {
      params: {
        available: params.available,
        start_time: params.start_time,
        end_time: params.end_time,
        pickup_location: params.pickup_location,
        company_id:  process.env.REACT_APP_ENV !== "prod"
            ? globalJson.dev_company_id
            : globalJson.prod_company_id,
      },
    });
    return { data: response.data }; // Return response data if successful
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      return {
        error:
          error.response.data.detail ||
          "An error occurred while fetching fleet data.",
      };
    }
    return { error: "An unknown error occurred." };
  }
};
