import React, { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import imagePng from "images/hero-right.png";
import HeroSearchForm from "components/HeroSearchForm/HeroSearchForm";
import { globalJson } from "global/global_json";
import img1 from "images/clientSay2.png";
import img2 from "images/clientSay3.png";
import img3 from "images/clientSay6.png";

export interface SectionHero4Props {
  className?: string;
}

const SectionHero4: FC<SectionHero4Props> = ({ className = "" }) => {
  return (
    <div
      className={`nc-SectionHero relative ${className}`}
      data-nc-id="SectionHero"
    >
      <div className="flex flex-col lg:flex-row lg:items-center">
        <div className="flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-8 sm:space-y-10 pb-14 lg:pb-64 xl:pr-14 lg:mr-10 xl:mr-0">
          <h2 className="font-bold text-3xl md:text-4xl xl:text-4xl !leading-[114%] text-primary-500 italic">
            Hassle Free Car Rentals <br />
            <span className="text-black not-italic">South Florida</span>
          </h2>
        </div>
        <div className="flex-grow">
          <img className="w-full" src={imagePng} alt="hero" />
        </div>
      </div>

      <div className="hidden lg:block z-10 mb-12 lg:mb-0 lg:-mt-60 w-full">
        <HeroSearchForm />
      </div>
    </div>
  );
};

export default SectionHero4;
